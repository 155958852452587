export function normalizeVideoUrl(url: string): string {
  // YouTube URL patterns
  const youtubePatterns = [
    // eslint-disable-next-line no-useless-escape
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^&?]{11})/i,
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/([^?]+)/i,
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/v\/([^?]+)/i,
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/shorts\/([^?]+)/i,
  ]

  // Vimeo URL patterns
  const vimeoPatterns = [
    /^(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(\d+)/i,
    /(?:https?:\/\/)?(?:www\.)?player\.vimeo\.com\/video\/(\d+)/i,
  ]

  for (const pattern of youtubePatterns) {
    const match = url.match(pattern)
    if (match) {
      return `https://www.youtube.com/watch?v=${match[1]}`
    }
  }

  for (const pattern of vimeoPatterns) {
    const match = url.match(pattern)
    if (match) {
      return `https://vimeo.com/${match[1]}`
    }
  }

  return '' // Return an empty string for unsupported URLs
}
