'use client'

import React, { createContext, useContext, useState } from 'react'

type VideoContextType = {
  playingVideoId: string | null
  setPlayingVideoId: (id: string | null) => void
}

const VideoContext = createContext<VideoContextType | undefined>(undefined)

export const VideoProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [playingVideoId, setPlayingVideoId] = useState<string | null>(null)

  return (
    <VideoContext.Provider value={{ playingVideoId, setPlayingVideoId }}>
      {children}
    </VideoContext.Provider>
  )
}

export const useVideo = () => {
  const context = useContext(VideoContext)
  if (context === undefined) {
    throw new Error('useVideo must be used within a VideoProvider')
  }
  return context
}
