'use client'

import dynamic from 'next/dynamic'
import React from 'react'

import type { DynamicComponentProps, Hero as HeroType } from '@/types'

import DefaultHero from './DefaultHero'

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const Hero: React.FC<DynamicComponentProps> = (props) => {
  const { layout, heading, excerpt = [] } = props as HeroType // Provide a default value for excerpt

  const componentName = layout ? `${capitalizeFirstLetter(layout)}Hero` : null

  const DynamicHeroComponent = React.useMemo(() => {
    if (componentName) {
      return dynamic<DynamicComponentProps>(
        () => import(`./${componentName}`).then((mod) => mod.default),
        {
          loading: () => <p>Loading...</p>,
        },
      )
    }
    return null
  }, [componentName])

  // Render the default hero if layout is not specified
  if (!layout) {
    return <DefaultHero title={heading || 'Default Title'} excerpt={excerpt} />
  }

  return (
    <div>
      {DynamicHeroComponent ? (
        <DynamicHeroComponent {...props} />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  )
}

export default Hero
