import type {
  PortableTextComponentProps,
  PortableTextComponents,
} from '@portabletext/react'
import { PortableText } from '@portabletext/react'
import type { PortableTextBlock } from '@portabletext/types'
import React from 'react'

type Props = {
  value: PortableTextBlock[]
}

// Custom component types that match the expected props
const customComponents: PortableTextComponents = {
  block: {
    h1: (props: PortableTextComponentProps<PortableTextBlock>) => (
      <h1>{props.children}</h1>
    ),
    h2: (props: PortableTextComponentProps<PortableTextBlock>) => (
      <h2>{props.children}</h2>
    ),
    h3: (props: PortableTextComponentProps<PortableTextBlock>) => (
      <h3>{props.children}</h3>
    ),
    h4: (props: PortableTextComponentProps<PortableTextBlock>) => (
      <h4>{props.children}</h4>
    ),
    normal: (props: PortableTextComponentProps<PortableTextBlock>) => (
      <p>{props.children}</p>
    ),
  },
  list: {
    bullet: ({ children }: PortableTextComponentProps<PortableTextBlock>) => (
      <ul className="list-disc pl-5 leading-5">{children}</ul>
    ),
    number: ({ children }: PortableTextComponentProps<PortableTextBlock>) => (
      <ol className="list-decimal pl-5 leading-5">{children}</ol>
    ),
  },
}

const Blocks: React.FC<Props> = ({ value }) => {
  return (
    <div className="prose py-2">
      <PortableText value={value} components={customComponents} />
    </div>
  )
}

export default Blocks
