'use client'

import React, { useEffect, useRef, useState } from 'react'

import type { FilmographyCard as FilmographyCardType } from '@/types'
import { normalizeVideoUrl } from '@/utils/normalizeVideoUrl'

import FilmographyList from './FilmographyList'
import { useVideo } from './VideoContext'

interface VideoPlayerProps {
  id: string
  url: string
  title: string
  filmography: FilmographyCardType[] // Ensure this is provided
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  id,
  url,
  title,
  filmography = [],
}) => {
  const { playingVideoId, setPlayingVideoId } = useVideo()
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const [embedUrl, setEmbedUrl] = useState<string | null>(null)
  const [error, setError] = useState(false)

  // This effect runs on every render to handle URL normalization
  useEffect(() => {
    const normalizedUrl = normalizeVideoUrl(url)

    if (!normalizedUrl) {
      console.error('Unsupported video URL:', url)
      setEmbedUrl(null)
      return
    }

    const isYouTube = normalizedUrl.includes('youtube.com')
    const isVimeo = normalizedUrl.includes('vimeo.com')

    if (isYouTube) {
      const videoId = normalizedUrl.split('v=')[1]?.split('&')[0]
      setEmbedUrl(`https://www.youtube.com/embed/${videoId}?enablejsapi=1`)
    } else if (isVimeo) {
      const videoId = normalizedUrl.split('/').pop()
      setEmbedUrl(`https://player.vimeo.com/video/${videoId}?api=1`)
    } else {
      console.error('Invalid video URL:', url)
      setEmbedUrl(null)
    }
  }, [url])

  // Handle messaging for playing state
  useEffect(() => {
    const iframe = iframeRef.current
    if (!iframe || !embedUrl) return

    const handleMessage = (event: MessageEvent) => {
      if (event.source !== iframe.contentWindow) return

      try {
        const data = JSON.parse(event.data)
        if (
          embedUrl.includes('youtube.com') &&
          data.event === 'onStateChange' &&
          data.info === 1
        ) {
          setPlayingVideoId(id)
        } else if (embedUrl.includes('vimeo.com') && data.event === 'play') {
          setPlayingVideoId(id)
        }
      } catch (error) {
        console.error('Error parsing message:', error)
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [id, embedUrl, setPlayingVideoId])

  // Handle pausing of videos
  useEffect(() => {
    const iframe = iframeRef.current
    if (!iframe || playingVideoId === id) return

    if (embedUrl?.includes('youtube.com')) {
      iframe.contentWindow?.postMessage(
        '{"event":"command","func":"pauseVideo","args":""}',
        '*',
      )
    } else if (embedUrl?.includes('vimeo.com')) {
      iframe.contentWindow?.postMessage('{"method":"pause"}', '*')
    }
  }, [playingVideoId, id, embedUrl])

  // Handle video load error
  const handleVideoLoadError = () => {
    setError(true)
  }

  // Add error handling for iframe load
  useEffect(() => {
    const iframe = iframeRef.current
    if (iframe) {
      iframe.onerror = handleVideoLoadError
    }
    return () => {
      if (iframe) {
        iframe.onerror = null // Cleanup
      }
    }
  }, [iframeRef])

  // Return early for unsupported URLs or errors
  if (!embedUrl || error) {
    return <FilmographyList films={filmography} />
  }

  return (
    <div className="relative w-full aspect-video">
      <iframe
        ref={iframeRef}
        src={embedUrl}
        width="100%"
        height="100%"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        title={title}
      />
    </div>
  )
}
