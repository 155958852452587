import Link from 'next/link'
import React from 'react'

import type { FilmographyCard as FilmographyCardType } from '@/types'

const FilmographyList: React.FC<{ films: FilmographyCardType[] }> = ({
  films,
}) => {
  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-[15px]">
        {films.map((film) => (
          <div key={film.title} className="p-2 border-b border-black">
            <h3 className="text-sm font-semibold">
              {film.watchLink ? (
                <Link
                  href={film.watchLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  {film.title}
                </Link>
              ) : (
                <span>{film.title}</span>
              )}
            </h3>
            <div className="text-xs">
              {film.roles && film.roles.length > 0
                ? film.roles.join(', ')
                : 'No roles specified'}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FilmographyList
