'use client'

import React, { useState } from 'react'

import type { ContactForm, DynamicComponentProps } from '@/types'

const ContactForm: React.FC<DynamicComponentProps> = (props) => {
  const { heading, tagline, buttonText, subjectOptions } = props as ContactForm
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    message: '',
    subject: '',
  })
  const [status, setStatus] = useState('')
  const [loading, setLoading] = useState(false)

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value } = e.target
    setFormData((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    setStatus('')

    const formDataToSubmit = new FormData(e.currentTarget)

    try {
      const body = new URLSearchParams()
      formDataToSubmit.forEach((value, key) => {
        body.append(key, value as string)
      })

      const response = await fetch('/__forms.html', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: body.toString(),
      })

      if (response.ok) {
        setStatus('Success! Your message has been sent.')
        setFormData({
          name: '',
          email: '',
          company: '',
          message: '',
          subject: '',
        }) // Reset the dropdown
      } else {
        setStatus('Error! Something went wrong.')
      }
    } catch (error) {
      console.error('Form submission error:', error)
      setStatus('Error! Unable to send your message.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <section className="w-full bg-black text-white py-16 px-4">
      <div className="container mx-auto flex flex-col md:flex-row">
        <div className="md:w-2/5 pr-8 mb-8 md:mb-0">
          <h2 className="text-4xl font-bold mb-4">{heading}</h2>
          <p className="text-sm text-gray-400">{tagline}</p>
        </div>
        <div className="md:w-3/5">
          <form name="contact" onSubmit={handleSubmit}>
            <input type="hidden" name="form-name" value="contact" />
            <div className="flex flex-col space-y-6">
              <div className="flex flex-col md:flex-row md:space-x-4">
                <div className="flex-1">
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full bg-black text-white pb-2 border-b border-gray-300 focus:outline-none focus:border-white"
                    required
                  />
                  <label htmlFor="name" className="block mt-2 text-sm">
                    Name
                  </label>
                </div>
                <div className="flex-1">
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full bg-black text-white pb-2 border-b border-gray-300 focus:outline-none focus:border-white"
                    required
                  />
                  <label htmlFor="email" className="block mt-2 text-sm">
                    E-mail
                  </label>
                </div>
              </div>
              <div>
                <input
                  type="text"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                  className="w-full bg-black text-white pb-2 border-b border-gray-300 focus:outline-none focus:border-white"
                />
                <label htmlFor="company" className="block mt-2 text-sm">
                  Company Name
                </label>
              </div>
              <div>
                <select
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  className="w-full bg-black text-white pb-2 border-b border-gray-300 focus:outline-none focus:border-white"
                  required
                >
                  <option value="" disabled></option>
                  {subjectOptions?.map((option: string, index: number) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <label htmlFor="subject" className="block mt-2 text-sm">
                  Subject
                </label>
              </div>
              <div>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Message"
                  className="w-full bg-black text-white pb-2 border-b border-gray-300 focus:outline-none focus:border-white resize-none"
                  rows={4}
                  required
                ></textarea>
              </div>
            </div>
            <div className="text-left mt-4">
              <button
                type="submit"
                className={`bg-black text-white border-2 border-white rounded-full px-8 py-2 hover:bg-white hover:text-black transition-colors duration-300 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={loading}
              >
                {loading ? 'Submitting...' : buttonText}
              </button>
            </div>
          </form>
          {status && (
            <p
              className={`mt-4 ${status.includes('Error') ? 'text-red-500' : 'text-green-500'}`}
            >
              {status}
            </p>
          )}
        </div>
      </div>
    </section>
  )
}

export default ContactForm
